.press {
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .press .holder {
      padding-bottom: 32px; } }
  @media only screen and (min-width: 1020px) {
    .press .holder {
      padding-top: 32px;
      padding-bottom: 41px; } }
  .press .press__list {
    align-items: center;
    display: flex;
    /*
    .nulltx {
      width: 38px;
      height: 47px;

      @include respond-to(handhelds) {
        width: 79.9px;
        height: 98.6px;
      }
    }
*/ }
    @media only screen and (max-width: 1019px) {
      .press .press__list {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 45px; } }
    @media only screen and (min-width: 1020px) {
      .press .press__list {
        justify-content: space-around; } }
    .press .press__list .svg-icon {
      fill: #797C91;
      color: #797C91; }
    .press .press__list .forbes {
      width: 80px;
      height: 21px; }
    .press .press__list .wired {
      width: 122px;
      height: 25px; }
    .press .press__list .venturebeat {
      width: 151px;
      height: 19px; }
    .press .press__list .tech-co {
      width: 78px;
      height: 26px; }
    .press .press__list .next-media {
      width: 67px;
      height: 32px; }
  @media only screen and (min-width: 1020px) {
    .press .press__title {
      margin-bottom: 40px; } }
  @media only screen and (max-width: 1019px) {
    .press .press__title {
      margin-top: 40px; } }
  @media only screen and (max-width: 1019px) {
    .press .press__item {
      margin-top: 40px;
      /*
      &:nth-last-child(2) {
        margin-left: 102px;
      }
      &:nth-last-child(1) {
        margin-right: 102px;
      }
      */ }
      .press .press__item:last-child {
        margin-left: auto;
        margin-right: auto; } }

@media only screen and (max-width: 1019px) {
  .press__1 .holder {
    padding-top: 60px;
    padding-bottom: 20px; } }

@media only screen and (min-width: 1020px) {
  .press__1 .holder {
    padding-top: 80px;
    padding-bottom: 40px; } }

@media only screen and (max-width: 1019px) {
  .press__1 .press__title {
    margin-top: 0; } }

.press__2 {
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .press__2 .holder {
      padding: 0 15px; } }
  @media only screen and (min-width: 1020px) {
    .press__2 .holder {
      padding-top: 0;
      padding-bottom: 0; } }
  .press__2 .press-wrapper {
    border-radius: 15px;
    background-color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .press__2 .press-wrapper {
        padding-top: 32px;
        padding-bottom: 32px; } }
    @media only screen and (min-width: 1020px) {
      .press__2 .press-wrapper {
        padding-top: 40px;
        padding-bottom: 40px; } }
  @media only screen and (max-width: 1019px) {
    .press__2 .press__title {
      margin-top: 0;
      margin-bottom: 4px; } }
  @media only screen and (min-width: 1020px) {
    .press__2 .press__title {
      margin-bottom: 24px; } }
  @media only screen and (max-width: 1019px) {
    .press__2 .press__item {
      margin: 24px 12px 0;
      /*
      &:nth-last-child(2) {
        margin-left: 102px;
      }
      &:nth-last-child(1) {
        margin-right: 102px;
      }
      */ }
      .press__2 .press__item:last-child {
        margin-left: 12px;
        margin-right: 12px; } }
  @media only screen and (min-width: 1020px) {
    .press__2 .press__item:not(:first-child) {
      margin-left: 65px; } }
  .press__2 .press__list {
    align-items: center;
    display: flex; }
    @media only screen and (max-width: 1019px) {
      .press__2 .press__list {
        justify-content: center;
        margin: 0; } }
    @media only screen and (min-width: 1020px) {
      .press__2 .press__list {
        justify-content: center; } }
    .press__2 .press__list .forbes {
      width: 74px;
      height: 19px;
      fill: #7A7A7B; }
    .press__2 .press__list .wired {
      width: 98px;
      height: 20px;
      color: #4B4B4C; }
    .press__2 .press__list .tech-co {
      width: 66px;
      height: 22px;
      fill: #444546;
      color: #BCBDBF; }
    .press__2 .press__list .next-media {
      width: 60px;
      height: 29px;
      fill: #48494A;
      color: #58595B; }
    .press__2 .press__list .venturebeat {
      width: 133px;
      height: 17px;
      fill: #353536;
      color: #353536; }
  @media only screen and (min-width: 1020px) {
    .press__2.press__2_1 .press-wrapper {
      background-color: transparent;
      padding: 0; } }
  .press__2.press__clp3 .press-wrapper {
    background-color: transparent;
    padding: 0; }
  .press__2.press__clp3 .press__title {
    font-family: 'Axiforma',sans-serif;
    font-size: 12px;
    letter-spacing: 0.92px;
    color: #637381;
    text-transform: uppercase; }
  .press__2.press__clp3 .press__item a a, .press__2.press__clp3 .press__item a svg {
    display: block; }

@media only screen and (max-width: 1019px) {
  .press__3 .holder {
    padding-top: 40px;
    padding-bottom: 70px; } }

@media only screen and (min-width: 1020px) {
  .press__3 .holder {
    padding-top: 40px;
    padding-bottom: 40px; } }

.press__3 .press__title {
  font-family: "Aleo", serif;
  font-size: 24px;
  letter-spacing: 0.44px;
  text-align: center;
  color: #4e526b;
  margin: 0; }

@media only screen and (max-width: 1019px) {
  .press__3 .press__item {
    margin: 8px 17px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .press__3 .press__item:last-child {
      width: 100%; } }

@media only screen and (min-width: 1020px) {
  .press__3 .press__item:not(:first-child) {
    margin-left: 80px; } }

.press__3 .press__link {
  display: block; }

.press__3 .press__list {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1019px) {
    .press__3 .press__list {
      justify-content: center;
      flex-wrap: wrap;
      margin: 15px 15px 0; } }
  @media only screen and (min-width: 1020px) {
    .press__3 .press__list {
      justify-content: center;
      margin-top: 30px; } }
  .press__3 .press__list .svg-icon {
    display: block;
    fill: #adadad;
    color: #adadad; }
  .press__3 .press__list .forbes {
    width: 77px;
    height: 21px; }
  .press__3 .press__list .venturebeat {
    width: 146px;
    height: 20px; }
  .press__3 .press__list .tech-co {
    width: 67px;
    height: 23px; }
  .press__3 .press__list .next-media {
    width: 62px;
    height: 30px; }
  .press__3 .press__list .merkle {
    width: 43px;
    height: 22px; }
