/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
.input.input-select {
  position: relative;
  text-align: left; }

.input.input-select__dropdown {
  cursor: pointer; }
  .input.input-select__dropdown:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 300ms ease;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    z-index: 2;
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-top: 6px solid #797c91;
    border-bottom: none; }

.input.input-select__countries {
  background-image: url(./img/united-states-of-america.svg);
  background-position: 15px center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  padding-left: 52px !important; }
  .input.input-select__countries .input-select-list-item {
    padding-left: 66px; }

.input.input-select__short {
  display: inline-block;
  min-width: auto;
  width: auto;
  line-height: normal;
  border: none;
  text-align: left;
  font-size: 14px; }
  .input.input-select__short .input-select-value {
    font-weight: bold; }
  @media only screen and (max-width: 1019px) {
    .input.input-select__short {
      width: 30px;
      height: 30px;
      background-position: center center;
      background-size: 100% 100%;
      background-color: transparent;
      padding: 0;
      border-radius: 50%; }
      .input.input-select__short:after {
        display: none; }
      .input.input-select__short .input-select-value {
        display: none; } }
  @media only screen and (min-width: 1020px) {
    .input.input-select__short {
      height: 34px;
      padding: 9px 23px 9px 38px;
      border-radius: 17px;
      background-position: 6px center; }
      .input.input-select__short.input-select__dropdown:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        display: block;
        z-index: 2;
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 4px solid #4e526b;
        border-bottom: none; } }
  .input.input-select__short .input-select-list {
    min-width: 150px;
    right: 0;
    left: auto;
    border: none;
    padding: 7px 0; }
  .input.input-select__short .input-select-list-item {
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding: 6px 0 6px 48px;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 300ms ease, color 300ms ease;
    position: relative; }
    .input.input-select__short .input-select-list-item:not(:first-child) {
      margin-top: 6px; }

.input.input-select__medium {
  display: inline-block;
  min-width: auto;
  width: auto;
  line-height: normal;
  border: none;
  text-align: left;
  font-size: 14px;
  height: 100%;
  padding: 0;
  background-color: transparent; }
  @media only screen and (max-width: 1019px) {
    .input.input-select__medium {
      width: 58px;
      background-size: 30px 30px;
      background-position: 12px center; } }
  @media only screen and (min-width: 1020px) {
    .input.input-select__medium {
      width: 70px;
      background-size: 40px 40px;
      background-position: 14px center; } }
  .input.input-select__medium.input-select__dropdown:after {
    border: none;
    width: 9px;
    height: 6px;
    background: url("./img/drop-icon.svg") no-repeat;
    right: 0;
    top: 51%; }
  .input.input-select__medium .input-select-value {
    font-weight: bold; }
  .input.input-select__medium .input-select-list {
    min-width: 150px;
    right: auto;
    left: 0;
    border: 1px solid #ccc;
    padding: 7px 0;
    top: 100%; }
  .input.input-select__medium .input-select-list-item {
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding: 6px 0 6px 48px;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 300ms ease, color 300ms ease;
    position: relative; }
    .input.input-select__medium .input-select-list-item:not(:first-child) {
      margin-top: 6px; }

.input:hover {
  border-color: #24a652; }

.input.input-select__open:after {
  transform: translateY(-50%) rotate(-180deg); }

.input.input-select__open .input-select-list {
  display: block; }

.input {
  background-color: #fff;
  min-width: 200px;
  width: 100%;
  height: 45px;
  padding: 11px 16px 10px;
  border: 1px solid #d5d6de;
  border-radius: 8px;
  color: #4e526b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  transition: background ease 300ms, border ease 300ms, color ease 300ms; }
  .input.placeholder {
    color: #abacb8; }
  .input:-moz-placeholder {
    color: #abacb8; }
  .input::-moz-placeholder {
    color: #abacb8; }
  .input:-ms-input-placeholder {
    color: #abacb8; }
  .input::-webkit-input-placeholder {
    color: #abacb8; }
  .input::-webkit-text-fill-color {
    color: #abacb8; }
  .input:focus {
    border-color: #24a652; }
  .input[disabled] {
    background: #f7f8fa;
    color: #abacb8; }

.input-select-list {
  background-color: #fff;
  width: 100%;
  padding: 10px 0;
  border: solid 1px #e4e5eb;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(212, 221, 230, 0.42);
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 3;
  display: none; }
  .input-select__scroll .input-select-list:before {
    content: "";
    display: block;
    background: #f7f8fa;
    width: 15px;
    height: 10px;
    border-left: solid 1px #e4e5eb;
    border-top: solid 1px #e4e5eb;
    border-right: solid 1px #e4e5eb;
    border-bottom: none;
    position: absolute;
    top: -1px;
    right: -1px;
    border-radius: 0 16px 16px 0; }
  .input-select__scroll .input-select-list:after {
    content: "";
    display: block;
    background: #f7f8fa;
    width: 15px;
    height: 10px;
    border-left: solid 1px #e4e5eb;
    border-bottom: solid 1px #e4e5eb;
    border-right: solid 1px #e4e5eb;
    border-top: none;
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-radius: 0 16px 16px 0; }

.input-select-list-item {
  background-position: 20px center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  padding: 11px 0 11px 16px;
  color: #4e526b;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: background-color 300ms ease, color 300ms ease;
  position: relative; }
  .input-select-list-item.input-select-list-item__selected {
    background-color: #24a652;
    color: #fff; }
    .input-select-list-item.input-select-list-item__selected:hover {
      color: #4e526b; }
  .input-select-list-item:hover {
    background-color: #c2f2d1; }
  .input-select__countries .input-select-list-item:nth-child(4) {
    margin-bottom: 20px; }
    .input-select__countries .input-select-list-item:nth-child(4):nth-last-child(1) {
      margin-bottom: 0; }
      .input-select__countries .input-select-list-item:nth-child(4):nth-last-child(1):after {
        display: none; }
    .input-select__countries .input-select-list-item:nth-child(4):after {
      content: "";
      display: block;
      background: #e4e5eb;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px; }

.input-select-list-item__us {
  background-image: url(./img/united-states-of-america.svg); }

.input-select-list-item__ca {
  background-image: url(./img/canada.svg); }

.input-select-list-item__gb {
  background-image: url(./img/united-kingdom.svg); }

.input-select-list-item__au {
  background-image: url(./img/australia.svg); }

.input-select-list-item__nz {
  background-image: url(./img/new-zealand.svg); }

.input-select-list-item__sg {
  background-image: url(./img/singapore.svg); }

.input-select-list-item__kr {
  background-image: url(./img/south-korea.svg); }

.input-select-list-item__hk {
  background-image: url(./img/hong-kong.svg); }

.input-select-list-item__ae {
  background-image: url(./img/united-arab-emirates.svg); }

.input-select-list-item__sa {
  background-image: url(./img/saudi-arabia.svg); }

.input-select-list-item__qa {
  background-image: url(./img/qatar.svg); }

.input-select-list-item__kw {
  background-image: url(./img/kuwait.svg); }

.input-select-list-item__ye {
  background-image: url(./img/yemen.svg); }

.input-select-list-item__om {
  background-image: url(./img/oman.svg); }

.input-select-list-item__bh {
  background-image: url(./img/bahrain.svg); }

.input-select-list-item__in {
  background-image: url(./img/india.svg); }

.input-select-list-item__ar {
  background-image: url(./img/argentina.svg); }

.input-select-list-item__br {
  background-image: url(./img/brazil.svg); }

.input-select-list-item__nz {
  background-image: url(./img/new-zealand.svg); }

.input-select-list-item__europe {
  background-image: url(./img/european-union.svg); }

.input-select-list-item__other {
  background-image: url(./img/other.png); }

.input-select__countries.input-select__us {
  background-image: url(./img/united-states-of-america.svg); }

.input-select__countries.input-select__ca {
  background-image: url(./img/canada.svg); }

.input-select__countries.input-select__gb {
  background-image: url(./img/united-kingdom.svg); }

.input-select__countries.input-select__au {
  background-image: url(./img/australia.svg); }

.input-select__countries.input-select__nz {
  background-image: url(./img/new-zealand.svg); }

.input-select__countries.input-select__sg {
  background-image: url(./img/singapore.svg); }

.input-select__countries.input-select__kr {
  background-image: url(./img/south-korea.svg); }

.input-select__countries.input-select__hk {
  background-image: url(./img/hong-kong.svg); }

.input-select__countries.input-select__ae {
  background-image: url(./img/united-arab-emirates.svg); }

.input-select__countries.input-select__sa {
  background-image: url(./img/saudi-arabia.svg); }

.input-select__countries.input-select__qa {
  background-image: url(./img/qatar.svg); }

.input-select__countries.input-select__kw {
  background-image: url(./img/kuwait.svg); }

.input-select__countries.input-select__ye {
  background-image: url(./img/yemen.svg); }

.input-select__countries.input-select__om {
  background-image: url(./img/oman.svg); }

.input-select__countries.input-select__bh {
  background-image: url(./img/bahrain.svg); }

.input-select__countries.input-select__ar {
  background-image: url(./img/argentina.svg); }

.input-select__countries.input-select__br {
  background-image: url(./img/brazil.svg); }

.input-select__countries.input-select__in {
  background-image: url(./img/india.svg); }

.input-select__countries.input-select__nz {
  background-image: url(./img/new-zealand.svg); }

.input-select__countries.input-select__europe {
  background-image: url(./img/european-union.svg); }

.input-select__countries.input-select__other {
  background-image: url(./img/other.png); }
